@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border text-[#dcdcdc];
  }

  input,
  select {
    background: #00000050;
    @apply border border-[#dcdcdc] rounded-md p-1;
  }

  option {
    background: black;
  }

  html,
  body {
    @apply bg-ash;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }
}

@layer components {
  .paper {
    @apply bg-white mx-auto my-2 overflow-clip;
  }

  .paper.letter {
    width: 8.5in;
    height: 11in;
  }

  .paper.a4 {
    width: 210mm;
    height: 297mm;
  }
}

@media print {
  *:not(:has(.paper)):not(.paper):not(.paper *) {
    display: none;
  }
  * {
    margin: 0 !important;
  }
  .container {
    width: min-content;
    border: none;
  }
  .paper {
    page-break-after: always;
  }
}
